var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "largeScreen" }, [
    _c("div", { staticClass: "prize" }, [
      _c("div", { staticStyle: { height: "60px" } }),
      _c("div", { staticClass: "prizeLevel" }, [_vm._v("一等奖")]),
      _c("div", { staticClass: "prizeName" }, [
        _vm._v(_vm._s(_vm.activityDetail.prize[0].productName))
      ]),
      _c("div", { staticClass: "prizeImg" }, [
        _c("img", {
          attrs: {
            src: JSON.parse(_vm.activityDetail.prize[0].imgUrl).productImg,
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "prizeNum" }, [
        _vm._v("奖品数量：" + _vm._s(_vm.activityDetail.prize[0].quantity))
      ])
    ]),
    _c("div", { staticClass: "erCode" }, [
      _c("div", { staticClass: "title" }, [_vm._v("现场抽奖")]),
      _c(
        "div",
        { staticClass: "codeBox" },
        [
          _c("ActiveQrcode", {
            staticClass: "codeUrl",
            attrs: { url: _vm.h5Url, codeSize: "400" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }