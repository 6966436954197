<template>
<div class="largeScreen">
<!--{{$route.query.activityId}}-->
  <div class="prize">
    <div style="height: 60px"></div>
    <div class="prizeLevel">一等奖</div>
    <div class="prizeName">{{activityDetail.prize[0].productName}}</div>
    <div class="prizeImg">
      <img :src="JSON.parse(activityDetail.prize[0].imgUrl).productImg" alt="">
    </div>
    <div class="prizeNum">奖品数量：{{activityDetail.prize[0].quantity}}</div>
  </div>
  <div class="erCode">
    <div class="title">现场抽奖</div>
    <div class="codeBox">
      <ActiveQrcode :url="h5Url"
                    class="codeUrl"
                    codeSize="400"></ActiveQrcode>
    </div>

  </div>
</div>
</template>

<script>
import { getIdActiveData } from '@/api/market'
import ActiveQrcode from '@/components/ActiveQrcode.vue'
import { getRoleIds } from '@/lib/util'
export default {
  components: {
    ActiveQrcode
  },
  name: 'largeScreen',
  data () {
    return {
      activityDetail: {}
    }
  },
  mounted () {
    getIdActiveData({
      id: this.$route.query.activityId
    }).then(res => {
      if (res.code == 200) {
        this.activityDetail = res.data
        this.h5Url = `${this.$url}/cube_web_api/auth/authWeChatUrl?userId=`
        const redirectUrl = encodeURIComponent(`${process.env.VUE_APP_WECHAT}/#/pages/shareIn/shareIn?activity=` + this.$route.query.activityId)
        this.h5Url = this.h5Url + getRoleIds().userId + '&redirectUrl=' + redirectUrl
      } else {
        this.$message.error(res.msg)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.largeScreen{
  width: 100%;
  height: 100%;
  //background-color: #00b7ff;
  background-image: url("~@/assets/img/largeScreen/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  .prize,.erCode{
    width: 500px;
    border: 4px solid #fff;
    border-radius: 20px;
    position: relative;
    //&:after{
    //  content: '';
    //  width: 100%;
    //  height: 100%;
    //  border-radius: 60px;
    //  border-left: 4px solid rgba(255,255,255,0);
    //  border-top: 4px solid rgba(255,255,255,0);
    //  border-right: 4px solid rgba(255,255,255,0.1);
    //  border-bottom: 4px solid rgba(255,255,255,0.1);
    //  position: absolute;
    //  right: -20px;
    //  bottom: -30px;
    //}
  }
  .prize{
    height: 800px;
    margin-right: 100px;
    text-align: center;
    color: #fff;
    .prizeLevel{
      font-size: 40px;
      font-weight: bold;
    }
    .prizeName{
      font-size: 30px;
      margin: 74px auto 30px;
    }
    .prizeImg{
      width: 350px;
      height: 350px;
      background-color: #fff;
      border-radius: 20px;
      display: flex;
      margin: auto;
      img{
        max-width: 80%;
        height: auto;
        margin: auto;
      }
    }
    .prizeNum{
      margin-top: 30px;
      font-size: 24px;
    }
  }
  .erCode{
    height: 500px;
    background-color: rgba(255,255,255,0.3);
    display: flex;
    position: relative;
    .title{
      width: 100%;
      position: absolute;
      top: -126px;
      color: #fff;
      font-size: 60px;
      font-weight: bold;
      transform: skewX(-10deg);
      text-align: center;
    }
    .codeBox{
      width: 450px;
      height: 450px;
      background-color: #fff;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
