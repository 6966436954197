// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/largeScreen/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".largeScreen[data-v-d18c7756] {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.largeScreen .prize[data-v-d18c7756], .largeScreen .erCode[data-v-d18c7756] {\n    width: 500px;\n    border: 4px solid #fff;\n    border-radius: 20px;\n    position: relative;\n}\n.largeScreen .prize[data-v-d18c7756] {\n    height: 800px;\n    margin-right: 100px;\n    text-align: center;\n    color: #fff;\n}\n.largeScreen .prize .prizeLevel[data-v-d18c7756] {\n      font-size: 40px;\n      font-weight: bold;\n}\n.largeScreen .prize .prizeName[data-v-d18c7756] {\n      font-size: 30px;\n      margin: 74px auto 30px;\n}\n.largeScreen .prize .prizeImg[data-v-d18c7756] {\n      width: 350px;\n      height: 350px;\n      background-color: #fff;\n      border-radius: 20px;\n      display: flex;\n      margin: auto;\n}\n.largeScreen .prize .prizeImg img[data-v-d18c7756] {\n        max-width: 80%;\n        height: auto;\n        margin: auto;\n}\n.largeScreen .prize .prizeNum[data-v-d18c7756] {\n      margin-top: 30px;\n      font-size: 24px;\n}\n.largeScreen .erCode[data-v-d18c7756] {\n    height: 500px;\n    background-color: rgba(255, 255, 255, 0.3);\n    display: flex;\n    position: relative;\n}\n.largeScreen .erCode .title[data-v-d18c7756] {\n      width: 100%;\n      position: absolute;\n      top: -126px;\n      color: #fff;\n      font-size: 60px;\n      font-weight: bold;\n      transform: skewX(-10deg);\n      text-align: center;\n}\n.largeScreen .erCode .codeBox[data-v-d18c7756] {\n      width: 450px;\n      height: 450px;\n      background-color: #fff;\n      margin: auto;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n}\n", ""]);
// Exports
module.exports = exports;
